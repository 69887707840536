import React from 'react'
// import Button from 'react-bootstrap/Button';
import {Container } from 'react-bootstrap';
// import Button from 'react-bootstrap/Button';
// import Form from 'react-bootstrap/Form';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
// import NavDropdown from 'react-bootstrap/NavDropdown';
import Offcanvas from 'react-bootstrap/Offcanvas';
import '../Header/Header.scss'
import Logo from '../../assets/images/logo.svg'

function Header() {
  return (
    <>
      <div>  
      <header className="my-navbar">      
      {['lg'].map((expand) => (
        <Navbar key={expand} expand={expand}>
          <Container>
            {/* <Navbar.Brand href="">LogoHere</Navbar.Brand> */}
            <Navbar.Brand href="">
              <img src={Logo} />
            </Navbar.Brand>
            <Navbar.Toggle aria-controls={`offcanvasNavbar-expand-${expand}`} />
            <Navbar.Offcanvas
              id={`offcanvasNavbar-expand-${expand}`}
              aria-labelledby={`offcanvasNavbarLabel-expand-${expand}`}
              placement="end"
            >
              <Offcanvas.Header closeButton>
                <Offcanvas.Title id={`offcanvasNavbarLabel-expand-${expand}`}>
                LogoHere
                </Offcanvas.Title>
              </Offcanvas.Header>
              <Offcanvas.Body>
                <Nav className="justify-content-end flex-grow-1 pe-3">
                  <Nav.Link href="#Home">Home</Nav.Link>
                  <Nav.Link href="#EasyToStartPlay">Easy to start play</Nav.Link>
                  <Nav.Link href="#HowToPlay">How to play</Nav.Link>
                  <Nav.Link href="#Feedbacks">Our customers</Nav.Link>
                  <Nav.Link href="#faq">Faq's</Nav.Link>
                </Nav>
              </Offcanvas.Body>
            </Navbar.Offcanvas>
          </Container>
        </Navbar>
      ))}  
      </header>    
      </div>
    </>
  )
}

export default Header