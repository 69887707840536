import React from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import AboutFootball from '../../assets/images/about-football.png';
import '../AboutUsSection/AboutUsSection.scss';

export default function AboutUsSection() {
  return (
    <>
    <section id="HowToPlay" className="about-section">
        <Container>
            <Row className='align-items-center justify-content-between'>
                <Col md={5} className="order-2">
                    {/* <div className='about-img'>
                        <img src={AboutFootball} alt="" />
                    </div> */}
                    <div className='about-video'>
                    <iframe src='https://www.youtube.com/embed/bD-BVLFZTa8?autoplay=1&mute=0' allow='autoplay; encrypted-media' title='video' />
                    {/* <iframe width="560" height="315" src="https://www.youtube.com/embed/bD-BVLFZTa8" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe> */}
                    </div>
                </Col>
                <Col md={6} className="order-1">
                    <div className='about-content'>
                        <div className="main-title">
                            <h2>How to play</h2>
                            <p>Ready to challenge other sports fans? Watch this video to learn how to play on FootballApp and get started now. Compete with sports fans, make a perfect FootballApp team with the best combination of players as per your knowledge and skill and win big.</p>
                        </div>
                    </div>
                </Col>
            </Row>
        </Container>
    </section>
    </>
  )
}
