import React from 'react'
import { Container, Row, Col, Card } from 'react-bootstrap';
import Howtoplay01 from '../../assets/images/howtoplay01.png';
import Howtoplay02 from '../../assets/images/howtoplay02.png';
import Howtoplay03 from '../../assets/images/howtoplay03.png';
import '../EasyToStartPlay/EasyToStartPlay.scss';

export default function EasyToStartPlay() {
  return (
    <>
        <section id="EasyToStartPlay" className='easy-to-start-play-section'>
            <Container>
                <Row className='gx-md-5 gy-5'>
                <Col md={12}>
                <div className='main-title'>
                    <h2>It's easy to start playing on FANTASYAPP</h2>
                </div>
                </Col>
                    <Col md={4}>
                        <Card className='card border-0'>
                            <div className='how-to-play-content'>
                                <span>1</span>
                                <div className='how-to-play-text'>
                                    <h4>Select a match</h4>
                                    <p>Choose an upcoming match from your favorite sport.</p>
                                </div>
                            </div>
                            <div className="how-to-play-img">
                                <img className='img-fluid' src={Howtoplay01} alt="" />
                            </div>
                        </Card>
                    </Col>
                    <Col md={4}>
                        <Card className='card border-0'>
                           <div className='how-to-play-content'>
                                <span>2</span>
                                <div className='how-to-play-text'>
                                    <h4>Create Team</h4>
                                    <p>Create your team with the best players using your skills.</p>
                                </div>
                            </div>
                            <div className="how-to-play-img">
                                <img className='img-fluid' src={Howtoplay02} alt="" />
                            </div>
                        </Card>
                    </Col>
                    <Col md={4}>
                        <Card className='card border-0'>
                            <div className='how-to-play-content'>
                                <span>3</span>
                                <div className='how-to-play-text'>
                                    <h4>Join League</h4>
                                    <p>From a wide range of contests choose the one you want to join.</p>
                                </div>
                            </div>
                            <div className="how-to-play-img">
                                <img className='img-fluid' src={Howtoplay03} alt="" />
                            </div>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </section>
    </>
  )
}
