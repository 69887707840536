import React from 'react'
import { Container, Row, Col, Form, InputGroup, Button } from 'react-bootstrap';
import downloadAppImg from '../../assets/images/download-app.png';
import CtaBg from '../../assets/images/cta-bg-shape.png';
import CtaImg from '../../assets/images/cta-img.png';
import '../GetDowloadLink/GetDoloadLink.scss';

export default function GetDoloadLink() {
  return (
    <>
        <section className='downloadlink-section'>
            <Container>
            <div className="downloadlink-card">
            <img className='cta-img' src={CtaBg} />
                <Row>
                <Col md={6}>
                    <div className="get-download-img">
                        {/* <img src={downloadAppImg} alt='' /> */}
                        <img src={CtaImg} alt='' />
                    </div>
                </Col>
                    <Col md={6}>
                    <div className='downloadlink-form'>
                        <h2>Download the official FantasyApp app</h2>
                        <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p>
                        <Form action=''>
                            <InputGroup className="mb-4">
                                <InputGroup.Text id="basic-addon1">+91</InputGroup.Text>
                                <Form.Control
                                placeholder="Enter Mobile Number"
                                aria-label="Enter Mobile Number"
                                aria-describedby="basic-addon1"
                                />
                            </InputGroup>
                            <Button className='btn btn-primary' type="button" variant="outline-primary">
                                <span>Get App Link</span>
                            </Button>
                        </Form>
                    </div>
                    </Col>
                </Row>
                </div>
            </Container>
        </section>
    </>
  )
}
